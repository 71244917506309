<template>
  <NuxtLink
    ref="linkRef"
    :to="getHref(node)"
    :class="{ opened }"
    @click="$emit('click', node.slug)"
  >
    <button v-if="hasChildren" @click.prevent.stop="toggle" />

    <span :class="{ active: isActiveNode(node) }">
      {{ node.title }}
    </span>
  </NuxtLink>

  <ul v-if="hasChildren" :class="{ opened }">
    <li v-for="child of node.children" :key="child.id">
      <HelpNavItemNew :node="child" @click="$emit('click', $event)" />
    </li>
  </ul>
</template>

<script setup lang="ts">
// TODO: add height animation

import useLocalePathWithSlash from '~/composables/useLocalePathWithSlash';
import type { HelpNestedTreeNode } from '~/types/help';

// constants
import { PATHS } from '~/constants';

// utils
import { isString } from '~/utils';

const props = defineProps<{
  node: HelpNestedTreeNode;
  version?: string;
}>();

defineEmits<{
  click: [slug: string];
}>();

const localePathWithSlash = useLocalePathWithSlash();

const hasChildren = computed(() => props.node.children?.length > 0);

const route = useRoute();
const slug = computed(() => route.params.slug);

const isActiveNode = (node: HelpNestedTreeNode): boolean =>
  Array.isArray(slug.value)
    ? slug.value.some(slug => slug === node.slug)
    : isString(slug.value) && slug.value === node.slug;

const hasActiveChild = (node: HelpNestedTreeNode): boolean => {
  if (isActiveNode(node)) return true;

  return node.children?.some(hasActiveChild) || false;
};

const active = computed(() => hasActiveChild(props.node));

const opened = ref(false);
const toggle = () => {
  opened.value = !opened.value;
};

watch(active, activeValue => {
  if (!opened.value && activeValue) {
    toggle()
  }
}, {
  immediate: true,
});

const getHref = (node: HelpNestedTreeNode) =>
  node.version
    ? localePathWithSlash(`${PATHS.HELP.path}${node.version}/${node.slug}`)
    : localePathWithSlash(`${PATHS.HELP.path}${node.slug}`);

const linkRef = ref<{ $el: HTMLElement } | null>(null);
const scrollTo = (target: HTMLElement, scrollContainer: HTMLElement): void => {
  const fromTopToTarget = target.offsetTop;
  const fromTopToScrollContainer = scrollContainer.offsetTop;

  scrollContainer.scrollTo({
    top: fromTopToTarget - fromTopToScrollContainer,
    behavior: 'smooth',
  });
};

onMounted(() => {
  if (!isActiveNode(props.node) || !linkRef.value) return;

  const scrollContainer = document.querySelector('.tabs-content');

  if (scrollContainer instanceof HTMLElement) {
    scrollTo(linkRef.value.$el, scrollContainer);
  }
});
</script>

<style scoped lang="scss">
$purple: #672e9c;

a {
  position: relative;

  font-family: Inter, sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;

  margin-bottom: 0.875rem;

  button {
    position: absolute;
    top: -0.1875rem;
    left: -1.5625rem;

    width: 1.5rem;
    height: 1.5rem;

    &:before,
    &:after {
      content: "";
      display: block;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:before {
      width: 1.5rem;
      height: 1.5rem;

      transition: background-color 0.15s ease;
    }

    &:after {
      border-top: 3px solid transparent;
      border-left: 6px solid #672e9c;
      border-bottom: 3px solid transparent;

      transition: transform 0.15s ease;
    }

    &:hover {
      &:before {
        background-color: #d8d8d8;
      }
    }
  }

  span {
    color: #212529;
    transition: color 0.15s ease;

    &.active {
      font-weight: 700;
    }
  }

  &:hover {
    span {
      color: $purple;
    }
  }

  &.opened {
    button {
      &:after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }
}

ul {
  display: none;
  margin-left: 1.5rem;

  li:not(:last-of-type) {
    margin-bottom: 0.875rem;
  }

  &.opened {
    display: block;

    margin-bottom: 0.875rem;
  }
}
</style>
